import React from 'react'
import './../custumer/progressbar.css'


function Progressbar() {


  return (

<div className=' contenedor-progress-donut'>

  <div className='ProgressGradient'>
    <ul className="chart-skills">
  <li>
    <span></span>
  </li>
  <li>
    <span></span>
  </li>
  <li>
    <span></span>
  </li>
  <li>
    <span></span>
   </li>
</ul>
</div> 




</div>





  )

}
export default Progressbar;
